<template>
    <div>
        <b-alert
            :show="segundosMensajeActualizacion"
            dismissible
            :variant="mensaje.variant"
            @dismissed="segundosMensajeActualizacion = 0"
            @dismiss-count-down="actualizarSegundosMensajeActualizacion"
        >
            {{ mensaje.texto }}
        </b-alert>

        <div v-if="renderComponent" ref="gantt" class="gantt-table" style='width:100%; height:100%;'></div>
        <!-- <div id="gantt_here" style='width:100%; height:100%;'></div> -->
    </div>
</template>

<script>
import { gantt } from "dhtmlx-gantt";
import { actividadMethods, actividadComputed } from "@/state/helpers";
import {
    actividadProyectoPersonalObraHorasTrabajadasMethods,
    actividadProyectoPersonalObraHorasTrabajadasComputed,
} from "@/state/helpers";
import { personalMethods, personalComputed } from "@/state/helpers";
import { unidadMethods, unidadComputed } from "@/state/helpers";
import "../../../../node_modules/dhtmlx-gantt/codebase/dhtmlxgantt.css";
export default {
    name: "gantt",
    props: {
        value: {
            type: Object,
            default() {
                return { tasks: [], links: [] };
            },
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        autoOpenSubTasks: {
            type: Boolean,
            default: true,
        },
        autoZoomToFit: {
            type: Boolean,
            default: true,
        },
        proyectName: {
            type: String,
            required: true,
        },
        proyectCode: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            recursos: [
                { key: 5, label: "Unassigned", parent: 4 },
                { key: 6, label: "John", parent: 1, unit: "hours/day" },
                { key: 7, label: "Mike", parent: 2, unit: "hours/day" },
                { key: 8, label: "Anna", parent: 2, unit: "hours/day" },
                { key: 9, label: "Bill", parent: 3, unit: "hours/day" },
                { key: 10, label: "Floe", parent: 3, unit: "hours/day" },
            ],
            mostrarRutaCritica: false,
            renderComponent: true,
            unidades: [],
            personalLista: [],
            actividadesCargando: false,
            personalCargando: false,
            n_direction: false,
            cachedSettings: null,
            showCriticalPath: false,
            scaleConfigs: [
               // hours
                {
                    name:"hour",
                    scale_height: 40,
                    scales:[{ unit: "day", format: "%d %M", step: 1 },
                    { unit: "hour", format: "%H:%i", step: 1}
                    ]
                },
                {
                    name:"hour",
                    scale_height: 40,
                    scales:[
                        { unit: "day", format: "%d %M", step: 1},
                        { unit: "hour", format: function(date){
                            var hourToStr = gantt.date.date_to_str("%H:%i");
                            var intervalEnd = new Date(gantt.date.add(date, 12, "hour") - 1)
                            return hourToStr(date) + " - " + hourToStr(intervalEnd);
                        }, step: 12},
                    ]
                },
			    // days
                {
                    name:"day",
                    min_column_width:120,
                    scale_height: 27,
                    scales:[
                        {unit: "day", step: 1, format: "%d %M"}
                    ]
                },
                // weeks
                {
                    name:"week",
                    scale_height: 50,
                    scales:[
                        {unit: "week", step: 1, format: function (date) {
                            var dateToStr = gantt.date.date_to_str("%d %M");
                            var endDate = gantt.date.add(date, -6, "day");
                            var weekNum = gantt.date.date_to_str("%W")(date);
                            return "#" + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                        }},
                        {unit: "day", step: 1, format: "%j %D"}
                    ]
                },
                // months
                {
                    name:"month",
                    scale_height: 50,
                    scales:[
                        {unit: "month", step: 1, format: "%F, %Y"},
                        {unit: "week", step: 1, format: function (date) {
                            var dateToStr = gantt.date.date_to_str("%d %M");
                            var endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
                            return dateToStr(date) + " - " + dateToStr(endDate);
                        }}
                    ]
                },
                // quarters
                {
                    name:"quarter",
                    height: 50,
                    scales:[
                        {
                            unit: "quarter", step: 3, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%M %y");
                                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        {unit: "month", step: 1, format: "%M"},
                    ]
                },
                // years
                {
                    name:"year",
                    scale_height: 50,
                    scales:[
                        {unit: "year", step: 5, format: function (date) {
                            var dateToStr = gantt.date.date_to_str("%Y");
                            var endDate = gantt.date.add(gantt.date.add(date, 5, "year"), -1, "day");
                            return dateToStr(date) + " - " + dateToStr(endDate);
                        }}
                    ]
                },
                // decades
                {
                    name:"year",
                    scale_height: 50,
                    scales:[
                        {unit: "year", step: 100, format: function (date) {
                            var dateToStr = gantt.date.date_to_str("%Y");
                            var endDate = gantt.date.add(gantt.date.add(date, 100, "year"), -1, "day");
                            return dateToStr(date) + " - " + dateToStr(endDate);
                        }},
                        {unit: "year", step: 10, format: function (date) {
                            var dateToStr = gantt.date.date_to_str("%Y");
                            var endDate = gantt.date.add(gantt.date.add(date, 10, "year"), -1, "day");
                            return dateToStr(date) + " - " + dateToStr(endDate);
                        }},
                    ]
                },
            ],
        };
    },
    created() {
        this.obtenerUnidadLocal();
    },
    methods: {
        ...actividadMethods,
        ...personalMethods,
        ...unidadMethods,
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        obtenerUnidadLocal() {
            this.personalCargando = true;
            this.obtenerUnidades()
                .then((res) => {
                    res.body.forEach((item) => {
                        let unidad = { key: "", label: "" };
                        unidad.key = item.unidad_id;
                        unidad.label = `${item.nombre_unidad}`;
                        this.unidades.push(unidad);
                    });
                    this.personalCargando = false;
                })
                .catch((error) => {
                    this.personalCargando = false;
                });
        },
        async obtenerTareas(cantidad,task){
            let tareas = []

             if(task.tindex == 0){
                var tarea = task.task 
                tarea.index = 1
                tarea.sort_order = 1
                tarea.parent = tarea.$rendered_parent
                tareas.push(tarea)
             }
            
            for (let index = 0; index < cantidad; index++) {
                var tarea = await gantt.getTaskByIndex(index); 

                tarea.index = tarea.$index + 1
                tarea.sort_order = tarea.$index+ 1
                tarea.parent = tarea.$rendered_parent
                await tareas.push(tarea)   
            }

            return tareas
        },
        $_initGanttEvents: function() {
           
            // if (!gantt.$_eventsInitialized) {
            gantt.i18n.setLocale("es");
            // gantt.config.autosize = true;
            gantt.plugins({
                auto_scheduling: true,
            });
            // gantt.config.work_time = true;

            // gantt.config.auto_scheduling = true;
            // gantt.config.auto_scheduling_strict = true;
            // gantt.config.auto_scheduling_compatibility = true;
            gantt.config.open_tree_initially = true;
            gantt.config.order_branch = "marker";
            // reordering tasks within the whole gantt
            gantt.config.order_branch_free = true;
            // gantt.config.sort = true; 
            gantt.config.buttons_left = ["gantt_delete_btn"];
            gantt.config.buttons_right = ["gantt_save_btn", "gantt_cancel_btn"];
            gantt.$_eventsInitialized = true;
            gantt.config.show_progress = false;
            gantt.config.drag_links = true;
            gantt.locale.labels.section_priority = "Priority";
            gantt.locale.labels.section_period = "Fecha";
            gantt.locale.labels.section_actividad = "Actividad";
            gantt.locale.labels.section_unidad = "Unidad";
            gantt.locale.labels.section_cantidadtotal = "Cantidad Total";
            gantt.config.lightbox.sections = [
                {
                    name: "unidad",
                    height: 40,
                    map_to: "unidad",
                    type: "select",
                    // focus: true,
                    options: this.unidades,
                },
                {
                    name: "cantidadtotal",
                    height: 30,
                    map_to: "cantidadtotal",
                    type: "textarea",
                },
                {
                    name: "actividad",
                    height: 30,
                    map_to: "text",
                    type: "textarea",
                    // focus: true,
                },

                { name: "period", type: "time", map_to: "auto" },
            ];

            gantt.config.scale_height = 30*2;
            gantt.config.min_column_width = 50;

            // ZOOM
            let zoomConfig = {
                levels: this.scaleConfigs,
                useKey: "ctrlKey",
                trigger: "wheel",
                element: function() {
                    return gantt.$root.querySelector(".gantt_task");
                },
            };

            gantt.ext.zoom.init(zoomConfig);

            gantt.plugins({
                tooltip: true,
                fullscreen: true,
                critical_path: true,
                marker: true,
            });

            gantt.attachEvent("onTaskSelected", (id) => {
                let task = gantt.getTask(id);
                this.$emit("task-selected", task);
            });

            gantt.attachEvent("onTaskIdChange", (id, new_id) => {
                if (gantt.getSelectedId() == new_id) {
                    let task = gantt.getTask(new_id);
                    this.$emit("task-selected", task);
                }
            });

            // LINKS
            gantt.attachEvent("onAfterLinkAdd", (id, item) => {
                const newLink = { id, item };
                this.$emit("onAfterLinkAdd", newLink);
            });

            gantt.attachEvent("onAfterLinkDelete", (id, item) => {
                const deleteLink = { id, item };
                this.$emit("onAfterLinkDelete", deleteLink);
            });

            // TASKS
            gantt.attachEvent("onBeforeTaskAdd", (id, item) => {
                if (item.cantidadtotal == '') {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto = "Debe ingresar una cantidad total";

                    this.segundosMensajeActualizacion = this.segundos;
                    document.querySelector('.gantt_cancel_btn_set').click()
                     return false
                }

                return true
            })
            gantt.attachEvent("onAfterTaskAdd", (id, item) => {
                const tG = gantt.getTask(id)
                var index = tG["$index"]
                const newTask = { id, item,index };
                    // Se transforma el padre a task tipo project
                    const parentId = item.parent;
                    if (parentId != '0') {
                        gantt.getTask(parentId).type =
                            gantt.config.types.project;
                        gantt.updateTask(parentId);
                    }
                    this.$emit("onAfterTaskAdd", newTask);
                
            });
             gantt.attachEvent("onBeforeTaskDelete", (id, item) => {
                //  var hijas =  gantt.getChildren(id)

                let tiene_hijas = gantt.hasChild(id)
                if(tiene_hijas > 0){
                    this.mensaje.variant = "danger";
                    this.mensaje.texto = "Primero debe eliminar las actividades hijas";

                    this.segundosMensajeActualizacion = this.segundos;
                    return false
                }
          
             })
            gantt.attachEvent("onAfterTaskDelete", (id, item) => {
                const deleteTask = { id, item };
                this.$emit("onAfterTaskDelete", deleteTask);
            });

            gantt.attachEvent("onBeforeTaskUpdate", (id, item) => {

                if (item.cantidadtotal == '') {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto = "Debe ingresar una cantidad total";

                    this.segundosMensajeActualizacion = this.segundos;
                     return false
                }

                return true
            })
            gantt.attachEvent("onAfterTaskUpdate", (id, item) => {
                const parentId = item.parent;
                if (parentId != '0') {
                    gantt.getTask(parentId).type =
                        gantt.config.types.project;
                    gantt.updateTask(parentId);
                }
                this.$emit("onAfterTaskUpdate", item, id);
            });

            gantt.attachEvent("onAfterTaskMove", async (id, parent, tindex) => {
                const task = gantt.getTask(id);
                const moveTask = { id, parent, tindex, task };
                let cantidad = gantt.getTaskCount()
                let tareas = await this.obtenerTareas(cantidad,moveTask)
                if (parent != '0') {
                    gantt.getTask(parent).type =
                        gantt.config.types.project;
                    gantt.updateTask(parent);
                    // this.$emit("onAfterTaskUpdate", task, id);
                }
                this.$emit("onAfterTaskMove", tareas);
             

            });

            gantt.attachEvent("onBeforeTaskMove", (id, parent, tindex) => {
                const task = gantt.getTask(id);
                var tasks = gantt.getTaskByTime(); 
            });
            //}
            gantt.templates.resource_cell_class = function(
                start_date,
                end_date,
                resource,
                tasks,
                assignments
            ) {
                var css = [];
                css.push("resource_marker");

                if (resource.$role === "task") {
                    css.push("task_cell");
                } else {
                    css.push("resource_cell");
                }

                var sum = assignments.reduce(function(total, assignment) {
                    return total + Number(assignment.value);
                }, 0);

                if (sum <= 8) {
                    css.push("workday_ok");
                } else {
                    css.push("workday_over");
                }
                return css.join(" ");
            };
            gantt.config.resource_render_empty_cells = true;
            gantt.config.columns = [
                {
                    name: "text",
                    label: "Tarea",
                    width: 250,
                    tree: true,
                    resize: true,
                    width: 200,
                },
                {
                    name: "start_date",
                    label: "Inicio",
                    width: 80,
                    align: "center",
                    resize: true,
                },
                {
                    name: "duration",
                    label: "Duración",
                    width: 60,
                    align: "center",
                },
                { name: "add", label: "", width: 50, align: "left" },
            ];



	

            // Buscador tarea
            document.addEventListener("DOMContentLoaded", function() {
                let filterValue = "";
                gantt.$doFilter = function(value) {
                    filterValue = value;
                    gantt.refreshData();
                };
                gantt.attachEvent("onBeforeTaskDisplay", function(id, task) {
                    if (!filterValue) return true;
                    const normalizedText = task.text.toLowerCase();
                    const normalizedValue = filterValue.toLowerCase();
                    return normalizedText.indexOf(normalizedValue) > -1;
                });
                gantt.attachEvent("onGanttRender", function() {
                    document.querySelector(
                        "[data-text-buscador]"
                    ).value = filterValue;
                });
            });
            //

            gantt.attachEvent("onAjaxError", function(request) {
                gantt.message({
                    type: "error",
                    text: "Http error " + request.status + "!",
                });
                gantt.message(request.response);
                return true;
            });

            gantt.templates.task_class = function(start, end, task) {
                if (gantt.hasChild(task.id)) return (task.color = "#ec9830");
            };

            // Marcadores
            const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
            const markerId = gantt.addMarker({
                start_date: new Date(), //a Date object that sets the marker's date
                css: "today", //a CSS class applied to the marker
                text: "Ahora", //the marker title
                title: dateToStr(new Date()), // the marker's tooltip
            });
            gantt.getMarker(markerId); //->{css:"today", text:"Now", id:...}
        },
        $_initDataProcessor: function() {
            if (!gantt.$_dataProcessorInitialized) {
                gantt.createDataProcessor((entity, action, data, id) => {
                    this.$emit(`${entity}-updated`, id, action, data);
                });
                gantt.$_dataProcessorInitialized = true;
            }
        },
       

            

        // updateCriticalPath() {
        //     this.mostrarRutaCritica = !this.mostrarRutaCritica;
        //     if (this.mostrarRutaCritica) {
        //         gantt.config.highlight_critical_path = true;
        //     } else {
        //         gantt.config.highlight_critical_path = false;
        //     }
        //     gantt.render();
        // },
        addLink(link) {
            let newLink = gantt.addLink(link);
        },
        deleteLink(linkId) {
            let deletedLink = gantt.deleteLink(linkId);
        },
        getGlobalTaskIndex(id) {
            let taskIndex = gantt.getGlobalTaskIndex(id);
            return taskIndex;
        },

        autoScrollToday() {
            gantt.showDate(new Date());
        },

        zoomToFit() {
            const project = gantt.getSubtaskDates(),
                areaWidth = gantt.$task.offsetWidth;
            let i;
            for (i = 0; i < this.scaleConfigs.length; i++) {
                const columnCount = this.getUnitsBetween(
                    project.start_date,
                    project.end_date,
                    this.scaleConfigs[i].unit,
                    this.scaleConfigs[i].step
                );
                if (
                    (columnCount + 2) * gantt.config.min_column_width <=
                    areaWidth
                ) {
                    break;
                }
            }

            if (i == this.scaleConfigs.length) {
                i--;
            }

            this.applyConfig(this.scaleConfigs[i], project);
            gantt.render();
        },
        getUnitsBetween(from, to, unit, step) {
            var start = new Date(from),
                end = new Date(to);
            var units = 0;
            while (start.valueOf() < end.valueOf()) {
                units++;
                start = gantt.date.add(start, step, unit);
            }
            return units;
        },
        applyConfig(config, dates) {
            gantt.config.scale_unit = config.scale_unit;
            if (config.date_scale) {
                gantt.config.date_scale = config.date_scale;
                gantt.templates.date_scale = null;
            } else {
                gantt.templates.date_scale = config.template;
            }

            gantt.config.step = config.step;
            gantt.config.subscales = config.subscales;

            if (dates && dates.start_date) {
                gantt.config.start_date = gantt.date.add(
                    dates.start_date,
                    -1,
                    config.unit
                );
                gantt.config.end_date = gantt.date.add(
                    gantt.date[config.unit + "_start"](dates.end_date),
                    2,
                    config.unit
                );
            } else {
                gantt.config.start_date = gantt.config.end_date = null;
            }
        },
        // saveConfig() {
        //   var config = gantt.config;
        //   this.cachedSettings = {};
        //   this.cachedSettings.scale_unit = config.scale_unit;
        //   this.cachedSettings.date_scale = config.date_scale;
        //   this.cachedSettings.step = config.step;
        //   this.cachedSettings.subscales = config.subscales;
        //   this.cachedSettings.template = gantt.templates.date_scale;
        //   this.cachedSettings.start_date = config.start_date;
        //   this.cachedSettings.end_date = config.end_date;
        // },
        // restoreConfig() {
        //   this.applyConfig(this.cachedSettings);
        // },
        autoOpenTasks() {
            this.value.tasks.forEach((task) => {
                task.open = true;
            });
        },
        updateCriticalPath() {
            this.showCriticalPath = !this.showCriticalPath;
            gantt.config.highlight_critical_path = this.showCriticalPath;
            gantt.render();
        },

        exportGanttMTHD(isPDF) {
            let _ext = "png";
            if (isPDF) {
                _ext = "pdf";
            }

            const projectDates = gantt.getSubtaskDates();
            const { data } = gantt;

            const parameters = {
                name: `gantt_${this.proyectCode}.${_ext}`,
                header: `<h1>Gantt proyecto ${this.proyectName} [${this.proyectCode}]</h1>`,
                // footer:"<h4> </h4>",
                locale: "en",
                start: this.formatDate(projectDates.start_date),
                end: this.formatDate(projectDates.end_date),
                // start:"2022-02-05",
                // end:"2022-02-08",
                skin: "terrace",
                data: data,
            };

            if (isPDF) {
                gantt.exportToPDF(parameters);
            } else {
                gantt.exportToPNG(parameters);
            }
        },
        exportGanttPDF() {
            gantt.exportToPDF({
                name: "gantt.pdf",
            });
        },

        exportGanttPNG() {
            gantt.exportToPNG({
                name: "gantt.png",
            });
        },

        formatDate(date) {
            let d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
    },
    mounted: function() {
        let externalScript = document.createElement("script");
        externalScript.setAttribute(
            "src",
            "http://export.dhtmlx.com/gantt/api.js"
        );
        document.head.appendChild(externalScript);

        this.$_initGanttEvents();
        gantt.clearAll();
        gantt.config.xml_date = "%d-%m-%Y";
        gantt.config.readonly = this.$props.readonly;
        gantt.config.fit_tasks = false;
        gantt.$zoomToFit = false;
        gantt.ext.zoom.setLevel("day");
        gantt.config.highlight_critical_path = false//this.showCriticalPath;
        gantt.templates.progress_text = function(start, end, task) {
            return (
                "<span style='text-align:left;color:#fff; font-weight: bold;'>" +
                Math.round(task.progress * 100) +
                "% </span>"
            );
        };
        gantt.init(this.$refs.gantt);

        this.autoOpenSubTasks && this.autoOpenTasks();
        gantt.parse(this.$props.value);
        this.$_initDataProcessor();

        // this.autoZoomToFit && this.zoomToFit();
    },
};
</script>

<style>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
.gantt_resources_filter_input {
    border: 1px solid #ccc;
    padding: 2px 10px !important;
}

.gantt_cal_ltext.gantt_resources_filter label {
    display: none;
}
.gantt_cal_lsection {
}
.gantt_cal_lsection label {
    font-weight: bold !important;
    /* font-size: 12px; */
}
#search {
    width: 170px;
    padding: 3px 5px 1px;
    font-size: 12px;
    margin-left: 27px;
}
.searchEl {
    height: 50px;
}
.searchEl:after {
    display: block;
    content: "";
    background: url(https://d2zjg0qo565n2.cloudfront.net/sites/default/files/2016-rebrand/header-icons/Homepage_Search_Icon.png)
        no-repeat;
    height: 20px;
    margin-top: -20px;
}
.gantt-table {
    height: 550px !important;
}
.task-color-1 .gantt_task_content {
    font-size: 13px !important;
    background-color: #10aa93;
}
.task-color-2 .gantt_task_content {
    font-size: 13px !important;
    background-color: #269ed2;
}
.task-color-3 .gantt_task_content {
    font-size: 13px !important;
    background-color: #ec9830;
}
.gantt_grid_data .gantt_row.odd:hover,
.gantt_grid_data .gantt_row:hover,
.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
    background-color: rgba(250, 215, 176, 0.461);
}
.gantt_btn_set.gantt_delete_btn_set {
    background: rgb(191, 191, 191);
    text-shadow: none !important;
}
.gantt_btn_set.gantt_save_btn_set {
    background: #ffa239;
    text-shadow: none !important;
}
.gantt-bar {
    display: flex;
    justify-content: space-between;
}
.gantt-zoom .button {
    border-radius: 0px !important;
    height: 27px !important;
}

* >>> .gantt_task_progress {
    text-align: left;
    padding-left: 10px;
    box-sizing: border-box;
    color: white;
    font-weight: bold;
}
.gantt_cal_light {
    width: 700px !important;
}
.owner-label {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    display: inline-block;
    border: 1px solid #cccccc;
    border-radius: 25px;
    background: #e6e6e6;
    color: #6f6f6f;
    margin: 0 3px;
    font-weight: bold;
}
.gantt_grid_scale .gantt_grid_head_cell,
.gantt_task .gantt_task_scale .gantt_scale_cell {
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}
/* 
		#gantt_here {
			width:100%;
			height:100%;
		} */

/* .gantt_grid_scale .gantt_grid_head_cell,
		.gantt_task .gantt_task_scale .gantt_scale_cell {
			font-weight: bold;
			font-size: 14px;
			color: rgba(0, 0, 0, 0.7);
		}

		.resource_marker{
			text-align: center;
		}
		.resource_marker div{
			color: black;
			background: none;
		}

		.resource_marker.resource_cell div{
			font-weight: bold;
			background-color: #d6d6d6;
		}
		.resource_marker.task_cell div {
			cursor: pointer;
		}

		.resource_marker.workday_ok div {
		}


		.resource_marker.workday_over div{
			color: red;
		}


		.owner-label{
			width: 20px;
			height: 20px;
			line-height: 20px;
			font-size: 12px;
			display: inline-block;
			border: 1px solid #cccccc;
			border-radius: 25px;
			background: #e6e6e6;
			color: #6f6f6f;
			margin: 0 3px;
			font-weight: bold;
		} */

.gantt_task_line.gantt_dependent_task {
    background-color: #65c16f;
    border: 1px solid #3c9445;
}

.gantt_task_line.gantt_dependent_task .gantt_task_progress {
    background-color: #46ad51;
}
.gantt_message_area {

    z-index: 999999 !important;
}

.btn-gantt{
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.gantt_resource_cell.gantt_resource_cell_value{
    display: none;
}

.gantt_resource_cell.gantt_resource_cell_label {
    width: 90%;
    max-width: 90%;
    vertical-align: middle;
}

.gantt_resource_row{
    padding: 0px 0;
}
</style>
