<template>
    <div>
        <b-overlay :show="loading" variant="white" rounded="sm">
            <div class="card">
                <div class="card-body">
                    <b-alert
                        :show="segundosMensajeActualizacion"
                        dismissible
                        :variant="mensaje.variant"
                        @dismissed="segundosMensajeActualizacion = 0"
                        @dismiss-count-down="
                            actualizarSegundosMensajeActualizacion
                        "
                    >
                        {{ mensaje.texto }}
                    </b-alert>

                    <div class="row datos-template">
                        <div class="col-12">
                            <div class="mb-3">
                                <label for="nombreTemplate">Nombre</label>
                                <input
                                    id="nombreTemplate"
                                    v-model="$v.form.nombreTemplate.$model"
                                    type="text"
                                    class="form-control"
                                    placeholder="Nombre Template"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.nombreTemplate.$error,
                                    }"
                                />
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.form.nombreTemplate.required
                                    "
                                    class="invalid-feedback"
                                >
                                    El campo es obligatorio.
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-3">
                                <label for="descripcionTemplate"
                                    >Descripción</label
                                >
                                <textarea
                                    id="descripcionTemplate"
                                    v-model="$v.form.descripcionTemplate.$model"
                                    rows="2"
                                    cols="50"
                                    class="form-control"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            $v.form.descripcionTemplate.$error,
                                    }"
                                ></textarea>
                                <div
                                    v-if="
                                        submitted &&
                                            !$v.form.descripcionTemplate
                                                .required
                                    "
                                    class="invalid-feedback"
                                >
                                    El campo es obligatorio.
                                </div>
                            </div>
                        </div>
                        <div class="text-end mt-2 mb-3">
                            <b-button variant="light" @click="volver"
                                >Volver</b-button
                            >
                            <b-button
                                @click="crearTemplateLocal(true)"
                                v-if="
                                    rolModuloPermiso.escritura &&
                                        editarGantt == false
                                "
                                type="button"
                                variant="primary"
                                class="ms-1 btn btn-success"
                                >
                                    <i class="mdi mdi-plus me-1"></i>
                                    Crear template
                                </b-button
                            >
                            <b-button
                                @click="crearTemplateLocal(false)"
                                v-if="rolModuloPermiso.escritura && editarGantt"
                                type="button"
                                variant="primary"
                                class="ms-1"
                                >Actualizar</b-button
                            >
                        </div>
                    </div>
                    <div v-if="mostrarGantt">
                        <Gantt
                            v-if="renderComponent"
                            class="left-container"
                            v-model="tasks"
                            style="width:100%; height:100%"
                            :readonly="!rolModuloPermiso.escritura"
                            :autoOpenSubTasks="false"
                            :autoZoomToFit="true"
                            :proyectName="projectTitle"
                            :proyectCode="projectCode"
                            @task-updated="logTaskUpdate"
                            @link-updated="logLinkUpdate"
                            @task-selected="selectTask"
                            @onAfterTaskAdd="onAfterTaskAdd"
                            @onAfterTaskDelete="onAfterTaskDelete"
                            @onAfterTaskUpdate="onAfterTaskUpdate"
                            @onAfterLinkAdd="onAfterLinkAdd"
                            @onAfterLinkDelete="onAfterLinkDelete"
                            @onBeforeRowDragEnd="onBeforeRowDragEnd"
                            @onAfterTaskMove="onAfterTaskMove"
                        ></Gantt>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import Gantt from "./gantt-template.vue";
import {
    actividadProyectoMethods,
    actividadProyectoComputed,
} from "@/state/helpers";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import {
    proyectoMethods,
    proyectoComputed,
    templateMethods,
} from "@/state/helpers";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    components: { Gantt },
    props: {
        codigoTemplate: String,
        template: Object,
        editarGantt: Boolean,
    },
    data() {
        return {
            gantt: null,
            projectTitle: "",
            projectCode: "",
            selectedTask: null,
            loading: false,
            mensaje: { variant: "", texto: "" },
            lugarInstalacion: {},
            codigoProyecto: "",
            codigoLugarInstalacion: "",
            segundos: 10,
            segundosMensajeActualizacion: 0,
            tasks: {
                data: [],
                links: [],
            },
            actividadesProyecto: [],
            actividadesProyectoCargando: false,
            renderComponent: true,
            rolModuloPermiso: {},
            proyectoSeleccionado: {},
            form: {
                nombreTemplate: null,
                descripcionTemplate: null,
            },
            submitted: false,
            esEditar: false,
            mostrarGantt: false,
        };
    },
    validations: {
        form: {
            nombreTemplate: { required },
            descripcionTemplate: { required },
        },
    },
    async created() {
        this.tasks = { data: [], links: [] };
        this.forceRerender();
        this.codigoLugarInstalacion = this.codigoTemplate;
        this.codigoProyecto = "TemplateGantt";
        this.lugarInstalacion = {
            codigo_lugar_instalacion: this.codigoLugarInstalacion,
            codigo_proyecto: this.codigoProyecto,
        };
        if (this.codigoTemplate != null) {
            await this.obtenerActividadesProyectoLocal();
            this.forceRerender();
        }

        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
    },
    mounted() {
        if (this.proyectoUsuario == null) {
            this.obtenerProyectoUsuario();
        } else {
            this.proyectoSeleccionado = this.proyectoUsuario;
            this.projectTitle = this.proyectoSeleccionado.nombre_proyecto;
        }

        if (this.codigoLugarInstalacion == null) {
            this.mostrarGantt = false;
        } else {
            this.mostrarGantt = true;

            this.$v.form.nombreTemplate.$model = this.template.nombre;
            this.$v.form.descripcionTemplate.$model = this.template.descripcion;
        }
    },
    methods: {
        ...actividadProyectoMethods,
        ...proyectoMethods,
        ...templateMethods,
        volver() {
            this.$router.push({
                name: "templateGantt",
            });
        },
        crearTemplateLocal(crear) {
            this.submitted = true;
            this.$v.form.$touch();

            if (this.$v.form.$invalid) {
                this.loading = false;
                return;
            }
            const nombre = this.$v.form.nombreTemplate.$model;
            const descripcion = this.$v.form.descripcionTemplate.$model;
            let tipo_proyecto = null;
            let activo = 1;
            let template_id = null;
            if (this.editarGantt) {
                tipo_proyecto = this.template.tipo_proyecto;
                activo = this.template.activo;
                template_id = this.template.template_id;
            }

            let templateNuevo = {
                nombre,
                descripcion,
                tipo_proyecto,
                activo,
            };

            let templateActualizar = {
                template_id,
                nombre,
                descripcion,
                tipo_proyecto,
                activo,
            };

            if (crear == false) {
                this.actualizarTemplate(templateActualizar)
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Template actualizado correctamente!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            } else {
                this.crearTemplate(templateNuevo)
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "Template creado correctamente!!!";
                            this.codigoLugarInstalacion == res.body.template_id;
                            this.$router.push({
                                name: "templateGanttGantt",
                                params: { templateId: res.body.template_id },
                            })
                            .then(() => { this.$router.go() });

                            this.mostrarGantt = true;
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "El Template ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;

                        this.loading = false;
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false;
                    });
            }

            this.submitted = false;
        },
        obtenerProyectoUsuario() {
            this.obtenerProyecto(this.$route.params.codigoProyecto)
                .then((res) => {
                    this.proyectoSeleccionado = res.body;
                    this.projectTitle = this.proyectoSeleccionado.nombre_proyecto;
                })
                .catch((error) => {});
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        async obtenerActividadesProyectoLocal() {
            this.loading = true;
            this.actividadesProyectoCargando = true;

            await this.obtenerActividadesProyectoTemplate({
                codigoLugarInstalacion: this.codigoLugarInstalacion,
                codigoProyecto: this.codigoProyecto,
            })
                .then((res) => {
                    this.actividadesProyecto = res.body;
                    this.tasks = { data: [], links: [] };
                    let data = [];
                    let dependencias = [];
                    if (this.actividadesProyecto.length > 0) {
                        this.actividadesProyecto.forEach((actividad) => {
                            let personalLista = [];
                            let responsableLista = [];

                            actividad.dependencias.forEach((dependencia) => {
                                let link = {
                                    id:
                                        dependencia.actividad_proyecto_dependencia_id,
                                    source: dependencia.source,
                                    target: dependencia.target,
                                    type: dependencia.type,
                                };
                                dependencias.push(link);
                            });

                            let task = {
                                id: actividad.actividad_proyecto_id,
                                text: actividad.actividad,
                                start_date: this.formatDate(
                                    actividad.start_date
                                ),
                                duration: actividad.duration,
                                progress: actividad.progress,
                                actividad_proyecto_id:
                                    actividad.actividad_proyecto_id,
                                depende_de: actividad.depende_de,
                                parent: actividad.parent,
                                total: actividad.total,
                                ponderacion: actividad.ponderacion,
                                unidad: actividad.unidad,
                                cantidadtotal: actividad.cantidad_total,
                                recurso: personalLista,
                                responsable: responsableLista,
                                sort_order: actividad.sort_order,
                                type:actividad.type,
                                index: actividad.sort_order,
                            };
                            data.push(task);
                        });
                        this.tasks.data = data;
                        this.tasks.links = dependencias;
                    }
                    // this.tasks.links = links;
                    // this.forceRerender();
                    this.actividadesProyectoCargando = false;
                    this.loading = false;
                })
                .catch((error) => {
                    this.actividadesProyectoCargando = false;
                    this.loading = false;
                });
        },
        onAfterTaskUpdate(item) {
            this.loading = true;
            let actividadActualizar = {
                codigo_proyecto: "",
                codigo_lugar_instalacion: "",
                actividad_id: "",
                actividad: "",
                depende_de: 0,
                duration: 0,
                start_date: "",
                end_date: "",
                cantidad_total: 0,
                ponderacion: 0,
                unidad: "",
                progress: 0,
                parent: "",
                gantt_id: 0,
                type:'',
                sort_order: 0,
                rut_personal_obra: "",
                nombre_personal_obra: "",
                recurso: [],
            };

            actividadActualizar.actividad_proyecto_id =
                item.actividad_proyecto_id;
            actividadActualizar.start_date = this.formatDate(item.start_date);
            actividadActualizar.end_date = this.formatDate(item.end_date);
            actividadActualizar.duration = item.duration;
            actividadActualizar.progress = item.progress;
            actividadActualizar.parent = item.id;
            actividadActualizar.recurso = [];
            actividadActualizar.responsable = [];
            actividadActualizar.actividad = item.text;
            actividadActualizar.unidad = item.unidad;
            actividadActualizar.cantidad_total = item.cantidadtotal;
            actividadActualizar.type = item.parent != '0' ? "gantt.config.types.project" : 'gantt.config.types.task'

            this.actualizarActividadGanttProyectoTemplate(actividadActualizar)
                .then((res) => {
                    if (res.body.status_code == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                            this.segundosMensajeActualizacion = this.segundos;
                    }

                    if (res.body.status_code == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Proyecto actualizado, si los cambios realizados no se ven correctamente, recargue la pagina web y compruebe la información";
                            // this.obtenerActividadesProyectoLocal();
                        this.segundosMensajeActualizacion = this.segundos;
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.loading = false;
                });
        },
        onAfterTaskDelete(actividad) {
            this.loading = true;
            this.eliminarActividadProyecto(actividad.item.actividad_proyecto_id)
                .then((res) => {
                    if (res.body.status_code == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.segundosMensajeActualizacion = this.segundos;
                    }

                    if (res.body.status_code == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Actividad eliminada,si los cambios realizados no se ven correctamente, recargue la pagina web y compruebe la información";
                        this.segundosMensajeActualizacion = this.segundos;
                        // this.obtenerActividadesProyectoLocal();
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "No se ha eliminado el registro, intente nuevamente";
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
        },
        onAfterTaskAdd(actividad) {
            let actividadActualizar = {
                codigo_proyecto: "",
                codigo_lugar_instalacion: "",
                actividad_id: "",
                actividad: "",
                depende_de: 0,
                duration: 0,
                start_date: "",
                end_date: "",
                total: 0,
                ponderacion: 0,
                unidad: "",
                progress: 0,
                parent: "",
                gantt_id: 0,
                type:"gantt.config.types.task",
                sort_order: 0,
                rut_personal_obra: "",
                nombre_personal_obra: "",
                recurso: [],
                responsable: [],
            };

            actividadActualizar.codigo_proyecto = this.codigoProyecto;
            actividadActualizar.codigo_lugar_instalacion = this.codigoLugarInstalacion;
            actividadActualizar.start_date = this.formatDate(
                actividad.item.start_date
            );
            actividadActualizar.end_date = this.formatDate(
                actividad.item.end_date
            );
            actividadActualizar.duration = actividad.item.duration;
            actividadActualizar.recurso = [];
            actividadActualizar.responsable = [];
            actividadActualizar.actividad = actividad.item.text;
            actividadActualizar.unidad = actividad.item.unidad;
            actividadActualizar.cantidad_total = actividad.item.cantidadtotal;
            actividadActualizar.parent = actividad.item.parent;
            actividadActualizar.gantt_id = parseInt(actividad.id);
            actividadActualizar.sort_order = parseInt(actividad.index) + 1;
            actividadActualizar.type = actividad.item.parent != '0' ? "gantt.config.types.project" : 'gantt.config.types.task'
            this.crearActividadProyectoGantt(actividadActualizar)
                .then(async (res) => {
                    if (res.body.status_code == 201) {
                        //  this.$router.go()
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Actividad creada, si los cambios realizados no se ven correctamente, recargue la pagina web y compruebe la información";
                        if (this.guardarCerrar) {
                            this.mostrarModal = false;
                        }
                    }

                    if (res.body.status_code == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto = "Las actividad ya existe!!!";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    // await this.obtenerActividadesProyectoLocal();
                    this.forceRerender()
                    //  this.$router.go()
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });
             
        },
        logTaskUpdate(actividad) {},
        logLinkUpdate(actividad) {},
        selectTask(actividad) {},
        onAfterLinkAdd(actividad) {
            let actividadActualizar = {
                actividad_proyecto_dependencia_id: 0,
                codigo_proyecto: "",
                codigo_lugar_instalacion: "",
                actividad_proyecto_id: "",
                source: "",
                target: "",
                type: "",
            };

            actividadActualizar.actividad_proyecto_dependencia_id = parseInt(
                actividad.item.id
            );
            actividadActualizar.codigo_proyecto = this.codigoProyecto;
            actividadActualizar.codigo_lugar_instalacion = this.codigoLugarInstalacion;
            actividadActualizar.actividad_proyecto_id = actividad.item.source;
            actividadActualizar.source = actividad.item.source;
            actividadActualizar.target = actividad.item.target;
            actividadActualizar.type = actividad.item.type;

            this.crearActividadProyectoDependenciaTemplate(actividadActualizar)
                .then((res) => {
                    if (res.status == 201) {
                        //  this.$router.go()
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Dependencia creada correctamente!!!";

                        if (this.guardarCerrar) {
                            this.mostrarModal = false;
                        } else {
                            // this.resetFormAgregarOtro();
                        }
                    }

                    if (res.status == 208) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "La dependencia ya existe ya existe!!!";
                    }

                    this.segundosMensajeActualizacion = this.segundos;
                    // this.obtenerActividadesProyectoLocal();
                    //  this.$router.go()
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.mostrarModal = false;
                    this.segundosMensajeActualizacion = this.segundos;
                    this.loading = false;
                });

        },
        onAfterLinkDelete(actividad) {
            this.loading = true;
            this.eliminarActividadProyectoDependencia(actividad.item.id)
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Dependencia eliminada correctamente!!!";

                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "No se ha eliminado el registro, intente nuevamente";
                    this.loading = false;
                });
        },
        onBeforeRowDragEnd(actividad) {},
        onAfterTaskMove(actividad) {
            this.loading = true;

            this.actualizarActividadProyectoOrden(actividad)
                .then((res) => {
                    if (res.status == 202) {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                    }

                    if (res.status == 204) {
                        this.mensaje.variant = "success";
                        this.mensaje.texto =
                            "Proyecto actualizado correctamente!!!";


                    }

                    this.loading = false;
                })
                .catch((error) => {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                        "Ha ocurrido un error, intente nuevamente";
                    this.loading = false;
                });

        },

        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        formatDate(value) {
            const duration = moment(value).format("DD-MM-yyyy");
            return `${duration}`;
        },
    },
    computed: { ...authUsuarioComputed, ...proyectoComputed },
};
</script>

<style>
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
.container {
    height: 100%;
    width: 100%;
}
.left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
}
</style>
