<template>
    <v-container>
        <PageHeader :title="title" :items="items" />

        <GanttComponente
        v-if="renderGanttComponente"
            :template="template"
            :codigoTemplate="codigoTemplate"
            :editarGantt="editar"
            @cerrar="cerrarGantt"
        />
    </v-container>
</template>
<script>
//import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { templateMethods, templateComputed } from "@/state/helpers";
import GanttComponente from "@/components/widgets/ganttTemplate/gantt-componente.vue";
import Swal from "sweetalert2";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
    page: {
        title: "Template Gantt",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        //Layout,
        PageHeader,
        GanttComponente,
    },

    data() {
        return {
            codigoTemplate: null,
            panelGantt: false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            title: "Template Gantt",
            items: [
                {
                    text: "Mantenedores",
                },
                {
                    text: "Proyectos",
                },
                {
                    text: "Template Gantt",
                    active: true,
                },
            ],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "nombre",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    sortable: false,
                },
                {
                    key: "nombre",
                    sortable: true,
                },
                {
                    key: "descripcion",
                    label: "Descripción",
                    sortable: true,
                },
                // {
                //     key: "activo",
                //     label: "activo",
                //     sortable: true,
                // },
            ],
            templateSeleccionado: {},
            templates: [],
            templatesCargando: false,
            template: {},
            rolModuloPermiso: {},
            editar: false,
            renderGanttComponente:true
        };
    },

    mounted() {
        let permisos = permiso.obtenerPermisos(
            this.user,
            this.$router.currentRoute.name
        );
        this.rolModuloPermiso = permisos;
        if (this.$route.params.templateId != null) {
            this.codigoTemplate = this.$route.params.templateId;
            this.editar = true;
            this.obtenerTemplateLocal();
        }
    },
    methods: {
        ...templateMethods,
        mostrarGantt() {
            this.panelGantt = true;
            this.codigoTemplate = null;
            this.editar = false;
            this.template = {};
        },
        editarGantt(item) {
            this.panelGantt = true;
            this.codigoTemplate = item.template_id;
            this.template = item;
            this.editar = true;
        },
        cerrarGantt() {
            this.obtenerTemplatesLocal();
            this.panelGantt = false;
        },
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },

        obtenerTemplateLocal() {
            this.obtenerTemplate(this.$route.params.templateId)
                .then((res) => {
                    this.template = res.body;
                    this.forceRerenderGantt()
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        forceRerenderGantt() {
            this.renderGanttComponente = false;
            this.$nextTick(() => {
                this.renderGanttComponente = true;
            });
        },
    },
    computed: {
        ...authUsuarioComputed,
        // ...templateComputed,
        rows() {
            return 1;
        },

        /**
         * Todo list of records
         */
        todoList() {
            return this.todos.length;
        },
    },
};
</script>
