var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"white","rounded":"sm"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-alert',{attrs:{"show":_vm.segundosMensajeActualizacion,"dismissible":"","variant":_vm.mensaje.variant},on:{"dismissed":function($event){_vm.segundosMensajeActualizacion = 0},"dismiss-count-down":_vm.actualizarSegundosMensajeActualizacion}},[_vm._v(" "+_vm._s(_vm.mensaje.texto)+" ")]),_c('div',{staticClass:"row datos-template"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"nombreTemplate"}},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.nombreTemplate.$model),expression:"$v.form.nombreTemplate.$model"}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.submitted &&
                                        _vm.$v.form.nombreTemplate.$error,
                                },attrs:{"id":"nombreTemplate","type":"text","placeholder":"Nombre Template"},domProps:{"value":(_vm.$v.form.nombreTemplate.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.nombreTemplate, "$model", $event.target.value)}}}),(
                                    _vm.submitted &&
                                        !_vm.$v.form.nombreTemplate.required
                                )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()])]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"descripcionTemplate"}},[_vm._v("Descripción")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.descripcionTemplate.$model),expression:"$v.form.descripcionTemplate.$model"}],staticClass:"form-control",class:{
                                    'is-invalid':
                                        _vm.submitted &&
                                        _vm.$v.form.descripcionTemplate.$error,
                                },attrs:{"id":"descripcionTemplate","rows":"2","cols":"50"},domProps:{"value":(_vm.$v.form.descripcionTemplate.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.form.descripcionTemplate, "$model", $event.target.value)}}}),(
                                    _vm.submitted &&
                                        !_vm.$v.form.descripcionTemplate
                                            .required
                                )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" El campo es obligatorio. ")]):_vm._e()])]),_c('div',{staticClass:"text-end mt-2 mb-3"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.volver}},[_vm._v("Volver")]),(
                                _vm.rolModuloPermiso.escritura &&
                                    _vm.editarGantt == false
                            )?_c('b-button',{staticClass:"ms-1 btn btn-success",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.crearTemplateLocal(true)}}},[_c('i',{staticClass:"mdi mdi-plus me-1"}),_vm._v(" Crear template ")]):_vm._e(),(_vm.rolModuloPermiso.escritura && _vm.editarGantt)?_c('b-button',{staticClass:"ms-1",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.crearTemplateLocal(false)}}},[_vm._v("Actualizar")]):_vm._e()],1)]),(_vm.mostrarGantt)?_c('div',[(_vm.renderComponent)?_c('Gantt',{staticClass:"left-container",staticStyle:{"width":"100%","height":"100%"},attrs:{"readonly":!_vm.rolModuloPermiso.escritura,"autoOpenSubTasks":false,"autoZoomToFit":true,"proyectName":_vm.projectTitle,"proyectCode":_vm.projectCode},on:{"task-updated":_vm.logTaskUpdate,"link-updated":_vm.logLinkUpdate,"task-selected":_vm.selectTask,"onAfterTaskAdd":_vm.onAfterTaskAdd,"onAfterTaskDelete":_vm.onAfterTaskDelete,"onAfterTaskUpdate":_vm.onAfterTaskUpdate,"onAfterLinkAdd":_vm.onAfterLinkAdd,"onAfterLinkDelete":_vm.onAfterLinkDelete,"onBeforeRowDragEnd":_vm.onBeforeRowDragEnd,"onAfterTaskMove":_vm.onAfterTaskMove},model:{value:(_vm.tasks),callback:function ($$v) {_vm.tasks=$$v},expression:"tasks"}}):_vm._e()],1):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }